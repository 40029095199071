import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import LoopIcon from "@mui/icons-material/Loop";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useOutletContext } from "react-router-dom";

const Posts = () => {
  const { user, messages } = useOutletContext();
  return (
    <Grid container display="flex" justifyContent="center">
      {messages.filter(
        (message) =>
          message.user_details.id === user.id &&
          message.user_details.content_hidden === false
      ).length > 0 ? (
        messages
          .filter(
            (message) =>
              message.user_details.id === user.id &&
              !message.user_details.content_hidden
          )
          .map((message) => (
            <Grid item mt={3} key={message.id}>
              <Card sx={{ maxWidth: 560, background: "transparent" }}>
                <Box display="flex" alignItems="flex-start">
                  <Avatar
                    src={message.user_details.profile_image.sharable}
                    alt="User"
                    sx={{ marginRight: 2 }}
                  />
                  <Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box>
                        <Typography variant="body2" fontWeight="bold">
                          {`${message.user_details.first_name} ${message.user_details.last_name}`}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {`${message.date} ${message.time}`}
                        </Typography>
                      </Box>
                      <Button
                        variant="contained"
                        sx={{
                          ml: "auto",
                          color: "#fff",
                          borderRadius: "20px",
                          height: "1.75rem",
                          backgroundColor: "#673AB3",
                          "&:hover": {
                            backgroundColor: "#9475c9",
                          },
                        }}
                      >
                        Events
                      </Button>
                    </Box>
                    <CardContent sx={{ padding: 0, marginTop: "0.5rem" }}>
                      <span style={{ fontSize: "0.85rem" }}>
                        {message.description}
                      </span>
                    </CardContent>
                    <CardMedia
                      component="img"
                      image={message.image_url.sharable}
                      alt="Concert"
                      sx={{
                        borderRadius: "15px",
                        marginTop: "1rem",
                        maxWidth: "100%",
                      }}
                    />
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button size="small" startIcon={<BarChartIcon />}>
                        {message.boost_count}
                      </Button>
                      <Button size="small" startIcon={<ThumbUpOffAltIcon />}>
                        {message.like_count}
                      </Button>
                      <Button size="small" startIcon={<LoopIcon />}>
                        {message.retweeted_count}
                      </Button>
                      <Button size="small" startIcon={<ThumbDownOffAltIcon />}>
                        {message.unlike_count}
                      </Button>
                      <Button
                        size="small"
                        startIcon={<PendingOutlinedIcon />}
                      ></Button>
                    </CardActions>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))
      ) : (
        <Typography variant="h6" color="#fff" mt={4}>
          No Posts Yet
        </Typography>
      )}
    </Grid>
  );
};

export default Posts;
