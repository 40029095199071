import React from "react";
import { SvgIcon } from "@mui/material";

const CustomCheckCircleIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="9" fill="#ff2557" />
    <path
      d="M10 16.5l-3-3 1.41-1.41L10 13.67l5.59-5.59L17 9l-7 7z"
      fill="#FFFFFF"
    />
  </SvgIcon>
);

export default CustomCheckCircleIcon;
