import { Grid, Divider } from "@mui/material";
import React from "react";
import ChatList from "./ChatList";
import ChatWindow from "./ChatWindow";

const Chats = () => {
  return (
    <Grid container spacing={2} justifyContent="flex-start">
      <Grid item xs={12} md={3} pl={0}>
        <ChatList />
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item xs={12} md={7}>
        <ChatWindow />
      </Grid>
    </Grid>
  );
};

export default Chats;
