import React from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  InputAdornment,
  InputBase,
  Paper,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import user1 from "../../Assets/profile/11.png";
import user2 from "../../Assets/profile/14.png";
import user3 from "../../Assets/profile/13.png";
import user4 from "../../Assets/profile/10.png";

const contacts = [
  {
    name: "Mikes Dinner",
    username: "@mikes_dinner",
    avatar: user1,
  },
  {
    name: "Georgie B.",
    username: "@georgie_boy",
    avatar: user2,
  },
  {
    name: "Wajahat Mirza",
    username: "@wajahat_mirza",
    avatar: user3,
  },
  {
    name: "Noah James",
    username: "@noah_james",
    avatar: user4,
  },
];

const Contacts = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ padding: 2, maxWidth: 560, margin: "0" }}>
      <Typography variant="h6">Contacts</Typography>
      <Paper
        style={{
          padding: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          margin: "10px auto",
          borderRadius: "25px",
        }}
      >
        <InputBase
          style={{ marginLeft: 8, flex: 1 }}
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </Paper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
          mt: 2,
        }}
      >
        <Typography variant="subtitle1">Your Contacts</Typography>
        <Button
          variant="text"
          onClick={() => navigate("/dashboard/add_contact")}
        >
          Add contact
        </Button>
      </Box>

      <List>
        {contacts.map((contact, index) => (
          <ListItem
            key={index}
            sx={{
              mt: 1,
              mb: 1,
            }}
            secondaryAction={
              <Button
                sx={{
                  color: "#ff2557",
                  border: "1px solid #ff2557",
                  borderRadius: 10,
                  fontSize: "12px",
                }}
              >
                Remove
              </Button>
            }
          >
            <ListItemAvatar>
              <Avatar src={contact.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  {contact.name}
                </Typography>
              }
              secondary={
                <Typography variant="body2" sx={{ fontSize: "11px" }}>
                  {contact.username}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box display="flex" justifyContent="space-between" sx={{ m: 2 }}>
        <Typography variant="subtitle1">Muted Accounts</Typography>
        <Button
          variant="text"
          onClick={() => navigate("/dashboard/muted_contacts")}
        >
          See All
        </Button>
      </Box>

      <Box display="flex" justifyContent="center" mt={3}>
        <Button
          variant="contained"
          sx={{
            width: "75%",
            backgroundColor: "#ff2557",
            borderRadius: "50px",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#e0224f",
            },
          }}
          onClick={() => navigate("/dashboard/add_contact")}
        >
          Add Contact
        </Button>
      </Box>
    </Box>
  );
};

export default Contacts;
