import {
  Box,
  Typography,
  Grid,
  Container,
  Divider,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";
import SideMenu from "./Layout/SideMenu";

const Dashboard = () => {
  const [messages, setMessages] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchMessage = async () => {
      const accessToken = sessionStorage.getItem("access-token");
      const tokenType = sessionStorage.getItem("token-type");
      const client = sessionStorage.getItem("client");
      const expiry = sessionStorage.getItem("expiry");
      const uid = sessionStorage.getItem("uid");
      const lat = 31.47162;
      const long = 74.355574;

      await axios
        .get(
          "https://ndkvkf2va7.execute-api.us-east-1.amazonaws.com/prod/messages",
          {
            headers: {
              "access-token": accessToken,
              "token-type": tokenType,
              client: client,
              expiry: expiry,
              uid: uid,
            },
            params: {
              lat: lat,
              long: long,
            },
          }
        )
        .then((response) => {
          setMessages(response.data.data.messages);
          setUser(response.data.data.user_details);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    fetchMessage();
  }, []);

  if (!user && !messages) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FD445D" }} />
        <Typography variant="h6" style={{ marginTop: "18px" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <Container
      maxWidth="100%"
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <SideMenu user={user} />
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
          <Outlet context={{ user, messages }} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
