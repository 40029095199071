import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  InputAdornment,
  Link,
  FormControl,
  Box,
  IconButton,
  createTheme,
  ThemeProvider,
  MenuItem,
  Select,
  OutlinedInput,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PhoneIcon from "@mui/icons-material/Phone";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ff2557",
      light: "#ff83a3",
    },
    matteBlack: {
      main: "#1f1f1f",
    },
    grayish: {
      main: "#f4f4f4",
    },
  },
});

const SignupForm = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isAgreed, setIsAgreed] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [googleForm, setGoogleForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    social_media_login: 1,
    login_with_google: true,
  });
  const [userError, setUserError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    gender: "",
    age: "",
    lat: "",
    long: "",
    code: "",
    date_of_birth: "",
    username: "",
    role: "regular",
    referral_code: "",
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData((prevFormData) => ({
            ...prevFormData,
            lat: position.coords.latitude,
            long: position.coords.longitude,
          }));
        },
        (error) => {
          setError("Error getting location. Please enable location services.");
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "username") {
      try {
        const response = await axios.post(
          "https://ndkvkf2va7.execute-api.us-east-1.amazonaws.com/prod/username",
          {
            username: value,
          }
        );

        const { data } = response;

        if (data.message === "Username has already been taken") {
          setUserError("Username has Already been Taken");
          setIsAgreed(false);
        } else {
          setUserError("");
          checkIfAgreed();
        }
      } catch (error) {
        console.error("Error checking username:", error);
      }
    }
    if (name === "email") {
      if (!validateEmail(value)) {
        setEmailError("Please enter a valid email address");
        setIsAgreed(false);
        return;
      }

      try {
        const response = await axios.post(
          "https://ndkvkf2va7.execute-api.us-east-1.amazonaws.com/prod/email",
          {
            email: value,
          }
        );

        const { data } = response;

        if (data.message === "Email has already been taken") {
          setEmailError("Email has Already been Taken");
          setIsAgreed(false);
        } else {
          setEmailError("");
          checkIfAgreed();
        }
      } catch (error) {
        console.error("Error checking Email:", error);
      }
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const checkIfAgreed = () => {
    if (userError === "" && emailError === "") {
      setIsAgreed(true);
    } else {
      setIsAgreed(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.lat || !formData.long) {
      setError("Location is required. Please enable location services.");
      return;
    } else if (!formData.first_name) {
      setError("Please Enter First Name.");
      return;
    } else if (!formData.last_name) {
      setError("Please Enter Last Name.");
      return;
    } else if (!formData.username) {
      setError("Please Enter Username.");
      return;
    } else if (!formData.email) {
      setError("Please Enter Email Address.");
      return;
    } else if (!formData.password) {
      setError("Please Enter password.");
      return;
    } else if (formData.password !== formData.password_confirmation) {
      setError("Confirm password is Incorrect");
      return;
    } else if (!formData.code) {
      setError("Please Select Country Code");
      return;
    } else if (!formData.phone) {
      setError("Please Enter Phone Number");
      return;
    } else if (!formData.gender) {
      setError("Please Select your Gender.");
      return;
    } else if (!formData.age) {
      setError("Please Enter Age.");
      return;
    } else if (!formData.date_of_birth) {
      setError("Please Enter Date of Birth.");
      return;
    }
    axios
      .post(
        "https://ndkvkf2va7.execute-api.us-east-1.amazonaws.com/prod/signup",
        formData
      )
      .then((res) => {
        console.log("You are registered!");
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          password_confirmation: "",
          phone: "",
          gender: "",
          age: "",
          lat: "",
          long: "",
          code: "",
          date_of_birth: "",
          username: "",
          role: "regular",
          referral_code: "",
        });
        navigate("/login");
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  const handleContinueWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user && user.access_token) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setGoogleForm((prevForm) => ({
            ...prevForm,
            first_name: res.data.given_name,
            last_name: res.data.family_name,
            email: res.data.email,
          }));
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  useEffect(() => {
    if (googleForm.email !== "") {
      axios
        .post(
          "https://ndkvkf2va7.execute-api.us-east-1.amazonaws.com/prod/login",
          googleForm
        )
        .then((res) => {
          if (res.status === 200 && res.data.headers) {
            console.log("User Login Successfully!!!");
            console.log(res);
            const accessToken = res.data.headers["access-token"];
            const tokenType = res.data.headers["token-type"];
            const client = res.data.headers["client"];
            const expiry = res.data.headers["expiry"];
            const uid = res.data.headers["uid"];
            sessionStorage.setItem("access-token", accessToken);
            sessionStorage.setItem("token-type", tokenType);
            sessionStorage.setItem("client", client);
            sessionStorage.setItem("expiry", expiry);
            sessionStorage.setItem("uid", uid);
            setGoogleForm({
              first_name: "",
              last_name: "",
              email: "",
              social_media_login: 1,
              login_with_google: true,
            });
            navigate("/dashboard");
          } else {
            console.log("Error Message=>", res.data.message);
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });

      const accessToken = sessionStorage.getItem("access-token");
      if (!accessToken) {
        navigate("/signup");
      }
    }
  }, [googleForm, navigate]);

  const handleContinueWithApple = () => {
    alert("Continue with Apple clicked");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="100vw"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <div style={{ width: "100%", maxWidth: "410px", textAlign: "center" }}>
          <Box style={{ textAlign: "center", marginBottom: "1rem" }}>
            <Typography
              component="h4"
              variant="h4"
              gutterBottom
              style={{ fontWeight: "bold", margin: "0", padding: "0" }}
            >
              locaided
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              gutterBottom
              style={{ fontWeight: "bold", margin: "0", padding: "0" }}
            >
              Create Your Account
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} justifyContent="center">
              <Grid
                item
                xs={6}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="first_name"
                    type="text"
                    autoComplete="given-name"
                    placeholder="First Name"
                    value={formData.first_name}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                      sx: {
                        backgroundColor: theme.palette.grayish.main,
                        "&:focus": {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "2.5rem",
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="last_name"
                    type="text"
                    autoComplete="family-name"
                    placeholder="Last Name"
                    value={formData.last_name}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                      sx: {
                        backgroundColor: theme.palette.grayish.main,
                        "&:focus": {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "2.5rem",
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="username"
                    type="text"
                    autoComplete="username"
                    placeholder="User Name"
                    value={formData.username}
                    onChange={handleChange}
                    error={Boolean(userError)}
                    helperText={userError}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                      sx: {
                        backgroundColor: theme.palette.grayish.main,
                        "&:focus": {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "2.5rem",
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="Email Address"
                    value={formData.email}
                    error={Boolean(emailError)}
                    helperText={emailError}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                      sx: {
                        backgroundColor: theme.palette.grayish.main,
                        "&:focus": {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "2.5rem",
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge="end"
                            aria-label="toggle password visibility"
                            size="small"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: {
                        backgroundColor: theme.palette.grayish.main,
                        "&:focus": {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "2.5rem",
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="password_confirmation"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    placeholder="Confirm Password"
                    value={formData.password_confirmation}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge="end"
                            aria-label="toggle password visibility"
                            size="small"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: {
                        backgroundColor: theme.palette.grayish.main,
                        "&:focus": {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "2.5rem",
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    variant="outlined"
                    name="code"
                    value={formData.code}
                    onChange={handleChange}
                    displayEmpty
                    input={<OutlinedInput />}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "2.5rem",
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px",
                        textAlign: "left",
                      },
                      backgroundColor: theme.palette.grayish.main,
                    }}
                  >
                    <MenuItem value="" disabled>
                      Country
                    </MenuItem>
                    <MenuItem value={"+1"}>(+1) United States</MenuItem>
                    <MenuItem value={"+92"}>(+92) Pakistan</MenuItem>
                    <MenuItem value={"+44"}>(+44) United Kingdom</MenuItem>
                    {/* Add more country codes as needed */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={8}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="phone"
                    type="tel"
                    autoComplete="tel"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIcon />
                        </InputAdornment>
                      ),
                      sx: {
                        backgroundColor: theme.palette.grayish.main,
                        "&:focus": {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "2.5rem",
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    variant="outlined"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    displayEmpty
                    input={<OutlinedInput />}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "2.5rem",
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px",
                        textAlign: "left",
                      },
                      backgroundColor: theme.palette.grayish.main,
                    }}
                  >
                    <MenuItem value="" disabled>
                      Gender
                    </MenuItem>
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="age"
                    type="number"
                    autoComplete="age"
                    placeholder="Age"
                    value={formData.age}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: theme.palette.grayish.main,
                        "&:focus": {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "2.5rem",
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="date_of_birth"
                    type="date"
                    autoComplete="bday"
                    placeholder="Date of Birth"
                    value={formData.date_of_birth}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      sx: {
                        backgroundColor: theme.palette.grayish.main,
                        "&:focus": {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "2.5rem",
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="referral_code"
                    type="text"
                    autoComplete="off"
                    placeholder="Referral Code (optional)"
                    value={formData.referral_code}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: theme.palette.grayish.main,
                        "&:focus": {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "2.5rem",
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    borderRadius: "50px",
                    backgroundColor: isAgreed
                      ? theme.palette.primary.main
                      : theme.palette.primary.light,
                    color: "white",
                    "&:hover": {
                      backgroundColor: isAgreed
                        ? theme.palette.primary.light
                        : theme.palette.primary.main,
                    },
                    height: "2.25rem",
                  }}
                  disabled={!isAgreed}
                >
                  Sign Up
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    borderRadius: "50px",
                    height: "2.25rem",
                    color: theme.palette.grayish.main,
                    backgroundColor: "#0096FF",
                  }}
                  startIcon={<GoogleIcon />}
                  onClick={handleContinueWithGoogle}
                >
                  Continue with Google
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    borderRadius: "50px",
                    height: "2.25rem",
                    color: theme.palette.matteBlack.main,
                    backgroundColor: "#fff",
                  }}
                  startIcon={<AppleIcon />}
                  onClick={handleContinueWithApple}
                >
                  Continue with Apple
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <Typography variant="body2">
                  Already have an account?{" "}
                  <span
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "#ff2557",
                    }}
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <div className="container">
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                      <Link
                        href="#"
                        color="inherit"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography variant="body2">About the App</Typography>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        href="#"
                        color="inherit"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography variant="body2">
                          Terms and Conditions
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        href="#"
                        color="inherit"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography variant="body2">Privacy Policy</Typography>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        href="#"
                        color="inherit"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography variant="body2">Imprint</Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default SignupForm;
