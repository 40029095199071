import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Switch,
  Link,
  Divider,
  Typography,
} from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SecurityIcon from "@mui/icons-material/Security";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import Brightness6OutlinedIcon from "@mui/icons-material/Brightness6Outlined";
import { useTheme } from "../../ThemeContext";

const Settings = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useOutletContext();
  const { darkMode, toggleTheme } = useTheme();

  const handleClickOpen = (id) => {
    setOpen(true);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box style={{ maxWidth: 560, padding: "10px" }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Typography variant="h5" ml={3}>
          Settings
        </Typography>
      </Box>
      <List sx={{ padding: "1rem 1rem 0 1rem" }}>
        <ListItem
          button
          style={{ padding: "10px" }}
          onClick={() => navigate("/dashboard/additional_settings")}
        >
          <ListItemIcon>
            <NotificationsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="General Settings" />
        </ListItem>
        <ListItem
          button
          style={{ padding: "10px" }}
          onClick={() => navigate("/dashboard/about_app")}
        >
          <ListItemIcon>
            <InfoOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="About the App" />
        </ListItem>
        <ListItem button style={{ padding: "10px" }}>
          <ListItemIcon>
            <SecurityIcon />
          </ListItemIcon>
          <ListItemText primary="Terms and Conditions" />
        </ListItem>
        <ListItem button style={{ padding: "10px" }}>
          <ListItemIcon>
            <AdminPanelSettingsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Privacy and Safety" />
        </ListItem>
        <ListItem
          button
          style={{ padding: "10px" }}
          onClick={() => navigate("/dashboard/deactivate_account")}
        >
          <ListItemIcon>
            <PersonRemoveOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Deactivate your account" />
        </ListItem>
        <ListItem style={{ padding: "10px" }}>
          <ListItemIcon>
            <Brightness6OutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Dark Theme" />
          <Switch edge="end" checked={darkMode} onClick={toggleTheme} />
        </ListItem>
        <Divider />
        <ListItem button style={{ padding: "10px", marginTop: "10px", }}>
          <Link underline="none">Upgrade Account</Link>
        </ListItem>
        <ListItem button style={{ padding: "10px" }} onClick={handleClickOpen}>
          <Link underline="none">Logout {user.username}</Link>
        </ListItem>
      </List>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#212121",
            color: "#fff",
            padding: "15px",
          },
          "& .MuiDialogTitle-root": {
            padding: "5px",
          },
          "& .MuiDialogContentText-root": {
            color: "#fff",
          },
          "& .MuiDialogContent-root": {
            padding: "5px",
          },
          "& .MuiDialogActions-root": {
            padding: "5px",
          },
        }}
      >
        <DialogTitle>Logout Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to Logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: "#A6A6A6",
              color: "#fff",
              "&:hover": { backgroundColor: "#B8B8B8" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleLogout}
            sx={{
              backgroundColor: "#FF2557",
              color: "#fff",
              "&:hover": { backgroundColor: "#FF2557" },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Settings;
