import React from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  InputBase,
  InputAdornment,
  Paper,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import user from "../../Assets/profile/1.png";

const ChatList = () => {
  const chats = [
    { name: "Mikes Dinner", avatar: user },
    { name: "Georgie B.", avatar: user },
  ];

  return (
    <div style={{ padding: "10px" }}>
      <h3>Messages</h3>
      <Paper
        style={{
          padding: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          margin: "10px auto",
          borderRadius: "25px",
        }}
      >
        <InputBase
          style={{ marginLeft: 8, flex: 1 }}
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </Paper>
      <List>
        {chats.map((chat, index) => (
          <ListItem button key={index}>
            <ListItemAvatar>
              <Avatar alt={chat.name} src={chat.avatar} />
            </ListItemAvatar>
            <ListItemText primary={chat.name} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default ChatList;
