import React from "react";
import { useOutletContext } from "react-router-dom";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  Stack,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Box,
} from "@mui/material";
import LiveFeedFilter from "../Layout/LiveFeedFilter";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import LoopIcon from "@mui/icons-material/Loop";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import BarChartIcon from "@mui/icons-material/BarChart";
import CommentIcon from "@mui/icons-material/Comment";
import VerifiedIconPng from "../../Assets/verified.png";
import Pic2 from "../../Assets/profile/2.jpg";
import Pic3 from "../../Assets/profile/3.jpg";
import Pic4 from "../../Assets/profile/4.jpg";

const Feed = () => {
  const { user, messages } = useOutletContext();
console.log(user)
  return (
    <Grid container spacing={2} justifyContent="flex-start">
      <Grid item xs={12} md={6} pl={0}>
        <Grid container>
          <Grid item sx={{ pb: "10px" }}>
            <Button
              disableElevation
              endIcon={<KeyboardArrowDownIcon />}
              style={{
                fontWeight: "bold",
              }}
            >
              Contacts
            </Button>
            <Stack
              direction="row"
              spacing={2}
              style={{ margin: "0.5rem", paddingBottom: "10px" }}
            >
              <Avatar
                alt="User"
                src={user.profile_image_url.sharable}
                style={{
                  alignItems: "center",
                  width: "10%",
                  height: "auto",
                }}
              />
              <Avatar
                alt="Status 1"
                src={Pic2}
                style={{
                  border: "3px solid #FD445D",
                  alignItems: "center",
                  width: "10%",
                  height: "auto",
                }}
              />
              <Avatar
                alt="Status 2"
                src={Pic3}
                style={{
                  border: "3px solid #FD445D",
                  alignItems: "center",
                  width: "10%",
                  height: "auto",
                }}
              />
              <Avatar
                alt="Status 3"
                src={Pic4}
                style={{
                  border: "3px solid #FD445D",
                  alignItems: "center",
                  width: "10%",
                  height: "auto",
                }}
              />
            </Stack>
            <Divider />
          </Grid>
          {messages.map((message) => (
            <Grid item key={message.id}>
              <Card
                sx={{
                  maxWidth: 560,
                  padding: "1rem",
                  background: "transparent",
                }}
              >
                <Box display="flex" alignItems="flex-start">
                  <Avatar
                    src={message.user_details.profile_image.sharable}
                    alt="User"
                    sx={{ marginRight: 2 }}
                  />
                  <Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <span style={{ fontWeight: "bold", fontSize: "13px" }}>
                        {`${message.user_details.first_name} ${message.user_details.last_name}`}
                      </span>
                      {message.user_details.verified ? (
                        <img
                          src={VerifiedIconPng}
                          alt="Verified"
                          style={{
                            width: "13px",
                            height: "13px",
                            marginLeft: "5px",
                          }}
                        />
                      ) : (
                        " "
                      )}
                      <span
                        style={{
                          color: "grey",
                          marginLeft: "5px",
                          fontSize: "13px",
                        }}
                      >
                        {message.user_details.username}
                      </span>
                    </Box>
                    <CardContent sx={{ padding: 0, marginTop: "0.5rem" }}>
                      <span style={{ fontSize: "0.75rem" }}>
                        {message.description}
                      </span>
                    </CardContent>
                    <CardMedia
                      component="img"
                      image={message.image_url.sharable}
                      alt="Message Image"
                      sx={{
                        borderRadius: "15px",
                        marginTop: "1rem",
                        maxWidth: "100%",
                      }}
                    />
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button size="small" startIcon={<BarChartIcon />}>
                        {message.boost_count}
                      </Button>
                      <Button size="small" startIcon={<ThumbUpOffAltIcon />}>
                        {message.like_count}
                      </Button>
                      <Button size="small" startIcon={<CommentIcon />}>
                        {message.replies_count}
                      </Button>
                      <Button size="small" startIcon={<LoopIcon />}>
                        {message.retweeted_count}
                      </Button>
                      <Button size="small" startIcon={<ThumbDownOffAltIcon />}>
                        {message.unlike_count}
                      </Button>
                      <Button
                        size="small"
                        startIcon={<PendingOutlinedIcon />}
                      ></Button>
                    </CardActions>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item xs={12} md={5}>
        <LiveFeedFilter />
      </Grid>
    </Grid>
  );
};

export default Feed;
