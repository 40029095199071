import React, { useMemo } from "react";
import Router from "./Routes";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  CssBaseline,
} from "@mui/material";
import { ThemeProvider, useTheme } from "./ThemeContext";

const App = () => {
  const { darkMode } = useTheme();

  const lightTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "light",
          background: {
            default: "#fff",
            paper: "#fff",
          },
          text: {
            primary: "#000",
            secondary: "#000",
          },
        },
        typography: {
          fontFamily: "Montserrat",
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                backgroundColor: "#fff",
                color: "#2D2D2D",
                "&:hover": {
                  backgroundColor: "#e2e2e2",
                },
              },
            },
          },
          MuiLink: {
            styleOverrides: {
              root: {
                color: "#000",
                "&:hover": {
                  color: "#FF2557",
                },
              },
            },
          },
          MuiCard: {
            styleOverrides: {
              root: {
                boxShadow: "none",
              },
            },
          },
          MuiSvgIcon: {
            styleOverrides: {
              root: {
                color: "#555",
              },
            },
          },
        },
      }),
    []
  );

  const darkTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
          background: {
            default: "#000000",
            paper: "#121212",
          },
          text: {
            primary: "#fff",
            secondary: "#fff",
          },
        },
        typography: {
          fontFamily: "Montserrat",
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                backgroundColor: "#000",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#2D2D2D",
                },
              },
            },
          },
          MuiLink: {
            styleOverrides: {
              root: {
                color: "#FFF",
                "&:hover": {
                  color: "#FF2557",
                },
              },
            },
          },
          MuiCard: {
            styleOverrides: {
              root: {
                boxShadow: "none",
              },
            },
          },
          MuiSvgIcon: {
            styleOverrides: {
              root: {
                color: "#fff",
              },
            },
          },
        },
      }),
    []
  );

  const theme = darkMode ? darkTheme : lightTheme;

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </MUIThemeProvider>
  );
};

const Root = () => (
  <ThemeProvider>
    <App />
  </ThemeProvider>
);

export default Root;
