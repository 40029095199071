import React, { useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { useTheme } from "../../ThemeContext";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VerifiedIconPng from "../../Assets/verified.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate, useOutletContext, Outlet } from "react-router-dom";
import LiveFeedFilter from "../Layout/LiveFeedFilter";

const UserProfile = () => {
  const navigate = useNavigate();
  const { darkMode } = useTheme();
  const [activeTab, setActiveTab] = useState("Posts");
  const { user, messages } = useOutletContext();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    navigate(tab.toLowerCase());
  };

  return (
    <Grid container spacing={2} justifyContent="flex-start">
      <Grid item xs={12} md={6}>
        <Box sx={{ maxWidth: 600, margin: "auto", padding: 1 }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <IconButton onClick={() => navigate("/dashboard")}>
              <ArrowBackIcon />
            </IconButton>
            <Avatar
              alt="Max Schmidt"
              src={user.profile_image_url.sharable}
              sx={{
                width: 100,
                height: 100,
                border: "4px solid",
                borderColor: darkMode ? "#fff" : "#000",
              }}
            />
            <IconButton>
              <EditIcon />
            </IconButton>
          </Box>
          <Box sx={{ marginTop: "1rem", textAlign: "center" }}>
            <Typography
              variant="body1"
              fontWeight="bold"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {user.full_name}
              {user.verified && (
                <img
                  src={VerifiedIconPng}
                  alt="Verified"
                  style={{ width: "20px", height: "20px", marginLeft: "5px" }}
                />
              )}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {user.username}
            </Typography>
          </Box>
          <Typography sx={{ fontSize: "0.85rem", textAlign: "center" }} mt={1}>
            {user.bio}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "2rem",
            }}
          >
            <span>{user.total_likes} Likes</span>
            <span>{user.total_unlikes} Dislikes</span>
            <span>72 Contacts</span>
            <span>
              <FiberManualRecordIcon sx={{ width: "12px" }} />
              <FiberManualRecordIcon sx={{ width: "12px" }} />
              <FiberManualRecordIcon sx={{ width: "12px" }} />
            </span>
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0 2rem",
            }}
          >
            {["Posts", "EYS", "Settings"].map((tab) => (
              <Link
                key={tab}
                underline="none"
                sx={{
                  textAlign: "center",
                  padding: "5px 2rem",
                  color: activeTab === tab ? "#FF2557" : "NONE",
                  borderBottom:
                    activeTab === tab ? "2px solid #FF2557" : "none",
                  cursor: "pointer",
                }}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </Link>
            ))}
          </Box>
          <Divider />
          <Outlet context={{ user, messages }} />
        </Box>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item xs={12} md={5}>
        <LiveFeedFilter />
      </Grid>
    </Grid>
  );
};

export default UserProfile;
