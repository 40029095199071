import React from "react";
import { CardMedia, Grid, Typography } from "@mui/material";
import { useOutletContext } from "react-router-dom";

const Evidences = () => {
  const { user, messages } = useOutletContext();
  const filteredMessages = messages.filter(
    (message) =>
      message.user_details.id === user.id &&
      !message.user_details.content_hidden
  );
  return (
    <Grid container mt={2} justifyContent="center">
      {filteredMessages.length > 0 ? (
        filteredMessages.map((message) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={message.id}>
            <CardMedia
              component="img"
              image={message.image_url.sharable}
              alt="Concert"
              sx={{
                borderRadius: 0,
                marginTop: "0.5rem",
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </Grid>
        ))
      ) : (
        <Typography variant="h6" color="#fff" mt={4}>
          No Evidences Yet
        </Typography>
      )}
    </Grid>
  );
};

export default Evidences;
