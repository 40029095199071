import React from "react";
import {
  Avatar,
  TextField,
  Paper,
  Typography,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import user from "../../Assets/profile/1.png";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

const ChatWindow = () => {
  const messages = [
    {
      text: "New Text Bubbles, take these as examples, including the colors.",
      time: "10:00",
      owner: false,
    },
    {
      text: "Also new Text bar with icons in the bottom of this chat Page. The time on the right is always displayed with the last text line.",
      time: "10:01",
      owner: true,
    },
    {
      text: "Also new Text bar with icons in the bottom of this chat Page. The time on the right is always displayed with the last text line.",
      time: "10:01",
      owner: false,
    },
    {
      text: "Also new Text bar with icons in the bottom of this chat Page. The time on the right is always displayed with the last text line.",
      time: "10:01",
      owner: true,
    },
    {
      text: "Also new Text bar with icons in the bottom of this chat Page. The time on the right is always displayed with the last text line.",
      time: "10:01",
      owner: false,
    },
    {
      text: "Also new Text bar with icons in the bottom of this chat Page. The time on the right is always displayed with the last text line.",
      time: "10:01",
      owner: true,
    },
    {
      text: "Also new Text bar with icons in the bottom of this chat Page. The time on the right is always displayed with the last text line.",
      time: "10:01",
      owner: false,
    },
    {
      text: "Also new Text bar with icons in the bottom of this chat Page. The time on the right is always displayed with the last text line.",
      time: "10:01",
      owner: true,
    },
    {
      text: "Also new Text bar with icons in the bottom of this chat Page. The time on the right is always displayed with the last text line.",
      time: "10:01",
      owner: false,
    },
    {
      text: "Also new Text bar with icons in the bottom of this chat Page. The time on the right is always displayed with the last text line.",
      time: "10:01",
      owner: true,
    },
    {
      text: "Also new Text bar with icons in the bottom of this chat Page. The time on the right is always displayed with the last text line.",
      time: "10:01",
      owner: false,
    },
    {
      text: "Also new Text bar with icons in the bottom of this chat Page. The time on the right is always displayed with the last text line.",
      time: "10:01",
      owner: true,
    },
  ];

  return (
    <Box sx={{ height: "95vh", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center", padding: 2 }}>
        <Avatar alt="Mikes Dinner" src={user} />
        <Typography variant="body2" sx={{ fontWeight: "bold", ml: 2 }}>
          Mikes Dinner
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          p: 2,
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
            borderRadius: "4px",
          },
        }}
      >
        {messages.map((message, index) => (
          <Paper
            key={index}
            sx={{
              p: 2,
              mb: 2,
              bgcolor: message.owner ? "#ff2557" : "#F5F5F5",
              color: message.owner ? "#FFF" : "#000",
              alignSelf: message.owner ? "flex-end" : "flex-start",
              maxWidth: "70%",
            }}
          >
            <Typography
              variant="body2"
              style={{
                fontSize: "13px",
              }}
            >
              {message.text}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              style={{
                textAlign: "right",
                fontSize: "12px",
                color: message.owner ? "#FFF" : "#000",
              }}
            >
              {message.time}
            </Typography>
          </Paper>
        ))}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
        <TextField
          variant="outlined"
          placeholder="Type message..."
          fullWidth
          sx={{ flex: 1, mr: 2, borderRadius: "15px", fontSize: "12px" }}
          InputProps={{
            sx: { borderRadius: "15px", fontSize: "12px" },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton sx={{ color: "#FFF" }}>
                  <CameraAltOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <IconButton sx={{ color: "#fff", p: 1, backgroundColor: "#ff2557" }}>
          <MicOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatWindow;
