import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Slider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import {
  Search as SearchIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import LaunchIcon from "@mui/icons-material/Launch";

const LiveFeedFilter = () => {
  const [radius, setRadius] = useState(10);
  const marks = [
    { value: 0, label: "0" },
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 25, label: "25" },
    { value: 30, label: "30" },
    { value: 35, label: "35" },
    { value: 40, label: "40" },
    { value: 45, label: "45" },
    { value: 50, label: "50" },
  ];

  const trendingLocations = [
    { city: "Langen", distance: "2 km away" },
    { city: "Offenbach", distance: "15 km away" },
    { city: "Frankfurt", distance: "40 km away" },
  ];

  const trendLocationPosts = [
    { city: "New York", posts: 4564, stories: 2320 },
    { city: "Mumbai", posts: 3954, stories: 1689 },
    { city: "Sydney", posts: 3547, stories: 2365 },
    { city: "Dubai", posts: 2946, stories: 1487 },
    { city: "London", posts: 2655, stories: 1563 },
  ];

  return (
    <Box p={2} sx={{ width: 360 }}>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Typography variant="h6" gutterBottom>
          Live Feed Filter
        </Typography>
        <IconButton style={{ fontSize: "16px", color: "#1A96F0" }}>
          Reset
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Search by Location"
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
            endAdornment: (
              <IconButton>
                <FilterListIcon />
              </IconButton>
            ),
          }}
        />
      </Box>
      <Box mb={2}>
        <Typography
          variant="body1"
          gutterBottom
          display="flex"
          alignItems="center"
        >
          <BorderColorIcon fontSize="small" sx={{ marginRight: 1 }} />
          Select Category
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography
          variant="body1"
          gutterBottom
          display="flex"
          alignItems="center"
        >
          <LaunchIcon fontSize="medium" sx={{ marginRight: 1 }} />
          Customize Radius
        </Typography>
        <Slider
          value={radius}
          onChange={(e, newValue) => setRadius(newValue)}
          step={5}
          min={0}
          max={50}
          valueLabelDisplay="auto"
          marks={marks}
          sx={{
            color: "#ff2557",
          }}
        />
        <Divider />
      </Box>
      <Typography variant="h6" gutterBottom>
        Trending 🔥
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Explore local hotspots and global buzz with real-time updates. See posts
        from the past 60 minutes to stay in sync with the world's pulse.
      </Typography>
      <List>
        {trendingLocations.map((location, index) => (
          <ListItem key={index} disableGutters style={{ padding: 0 }}>
            <ListItemText
              primary={
                <span style={{ fontSize: "0.8rem" }}>{location.city}</span>
              }
              secondary={
                <span style={{ fontSize: "0.8rem" }}>{location.distance}</span>
              }
            />
          </ListItem>
        ))}
        <Divider />
        {trendLocationPosts.map((post, index) => (
          <ListItem key={index} disableGutters style={{ padding: 0 }}>
            <ListItemText
              primary={<span style={{ fontSize: "0.8rem" }}>{post.city}</span>}
              secondary={
                <span
                  style={{ fontSize: "0.8rem" }}
                >{`${post.posts} Posts, ${post.stories} Stories`}</span>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default LiveFeedFilter;
